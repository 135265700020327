/* line 13, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart {
	margin-bottom: 20px;
}

/* line 16, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart .google-visualization-table-table {
	border: none;
	background-color: transparent;
}

/* line 21, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart .google-visualization-table {
	border: none;
}

/* line 24, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart .google-visualization-table > div:first-child {
	border: none;
}

/* line 29, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart .header-row {
	background: rgb(var(--color-global-widget));
}

/* line 34, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart .google-visualization-table-th {
	padding: 10px 5px;
	border: none;
	font-weight: normal;
}

/* line 39, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart .google-visualization-table-th.gradient {
	background: none;
}

/* line 44, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart .table-row,
.m-marketdata-snapshot-chart .odd-row {
	color: rgb(var(--color-global-negative));
}

/* line 48, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart .table-row:hover, .m-marketdata-snapshot-chart .table-row.table-row-selected,
.m-marketdata-snapshot-chart .odd-row:hover,
.m-marketdata-snapshot-chart .odd-row.table-row-selected {
	background-color: rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-positive));
	cursor: pointer;
}

/* line 55, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart .table-row .google-visualization-table-td,
.m-marketdata-snapshot-chart .odd-row .google-visualization-table-td {
	padding: 10px 5px;
	border: none;
	text-align: center;
}

/* line 60, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart .table-row .google-visualization-table-td:nth-child(2),
.m-marketdata-snapshot-chart .odd-row .google-visualization-table-td:nth-child(2) {
	text-align: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-marketdata-snapshot-chart .table-row .google-visualization-table-td:nth-child(2), .is-rtl
.m-marketdata-snapshot-chart .odd-row .google-visualization-table-td:nth-child(2) {
	text-align: right;
}

/* line 66, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.m-marketdata-snapshot-chart .odd-row {
	background-color: rgb(var(--color-global-widget));
}

/* line 71, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.marketdata-snapshot-chart__chart-title {
	margin-bottom: 10px;
	text-transform: capitalize;
}

/* line 75, scss/80-themes/Saunders/70-modules/listingmarketdatamarketsnapshotchart/module.scss */
.marketdata-snapshot-chart__chart-title--colored {
	color: rgb(var(--color-global-text));
}

/*# sourceMappingURL=../../../../../true */